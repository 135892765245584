import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITableDataSource } from '@suvo-bi-lib';
import { environment } from 'apps/mba-cpr-survey-portal/src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { TableApiService } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/services/table-api.service';
import { ITableOptions } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-options.interface';
import { IMeasurement } from '../measurement/measurement.interface';
import { ITaxon, TCountingMethod } from './taxon.interface';

export interface HierarchyFilter {
  measurements?: IMeasurement[];
  countingMethod?: TCountingMethod;
}

@Injectable()
export class TaxonService extends TableApiService<ITaxon> {
  private flat: ITaxon[];
  private hierarchy: ITaxon[];

  constructor(http: HttpClient) {
    super(http, `${environment.cprServiceApi}taxon/`);
  }

  async refreshHierarchy(){
    this.flat = null;
    this.hierarchy = null;

    await this.loadFullHierarchy();
  }

  private async loadFullHierarchy() {
    if (!this.flat || !this.hierarchy) {
      const taxons = await lastValueFrom(
        this.httpClient.get<ITaxon[]>(`${environment.cprServiceApi}taxon/all`),
      );

      const uniqueTaxons: Record<number, ITaxon> = {};

      this.hierarchy = this.sortAlphabetical(
        taxons.filter((taxon) => {
          taxon.taxonChildren = this.sortAlphabetical(
            taxons.filter((next) => !!next.parentTaxonIds?.includes(taxon.cprTaxonId)),
          );

          if (taxon.cprTaxonId) {
            if (uniqueTaxons[taxon.cprTaxonId]) {
              return false;
            } else {
              uniqueTaxons[taxon.cprTaxonId] = taxon;
            }
          }
          return !taxon.parentTaxonIds?.length;
        }),
      );

      this.flat = this.sortAlphabetical(Object.values(uniqueTaxons));
    }
  }

  async getFlat() {
    if (!this.flat) {
      await this.loadFullHierarchy();
    }
    return this.flat;
  }

  /**
   * Gets the entire taxon hierarchy. Pass a filter object to filter based on provided measurements and/or counting method.
   */
  async getHierarchy(filter?: HierarchyFilter) {
    // Recursive function
    const cloneAndFilter = (taxons?: ITaxon[]) =>
      structuredClone((taxons ?? this.hierarchy) as (ITaxon & { measurement?: IMeasurement })[])
        .map((taxon) => {
          taxon.taxonChildren = cloneAndFilter(taxon.taxonChildren);
          taxon.measurement = filter?.measurements?.find((v) => v.taxonId === taxon._id);
          return taxon;
        })
        .filter((taxon) => {
          if (filter?.measurements && !taxon.measurement && !taxon.taxonChildren?.length) {
            // If filtering by measurements, don't include those without measurements
            // We keep parents without measurement so long as at least one child has a measurement
            // The children will be filtered too, so if .taxonChildren is empty, it means none have measurements
            return false;
          }
          if (filter?.countingMethod && taxon?.countingMethod !== filter.countingMethod) {
            // If filtered by counting method, don't include any that aren't of that counting method
            return false;
          }
          return true;
        });

    if (!this.hierarchy) {
      await this.loadFullHierarchy();
    }

    return cloneAndFilter(this.hierarchy);
  }

  async hasChildren(id: string) {
    return !!(await this.getFlat()).find(
      (taxon) => taxon._id === id && taxon.taxonChildren?.length,
    );
  }

  async getById(id: string) {
    return (await this.getFlat()).find((taxon) => taxon._id === id);
  }

  async getByCprId(id: number) {
    return (await this.getFlat()).find((taxon) => taxon.cprTaxonId === id);
  }

  /** @deprecated We have local copies of the taxons now and should use those. */
  get<T>(suffix?: string | string[], params?: any): Promise<T> {
    return super.get(suffix, params);
  }

  /** @deprecated We have local copies of the taxons now and should use those. */
  getPaginated(
    suffix: string | string[] = '',
    options: ITableOptions,
  ): Promise<ITableDataSource<ITaxon>> {
    return super.getPaginated(suffix, options);
  }

  /** @deprecated Use `getById` and `getByCprId` instead. */
  getOne(suffix: string | string[] = '', id: string): Promise<ITaxon> {
    return super.getOne(suffix, id);
  }

  sortAlphabetical(taxons: ITaxon[]) {
    return [...taxons].sort((a, b) => a.taxonName.localeCompare(b.taxonName));
  }

  /**
   * Searches the taxa directory by matching the search term with the taxon name
   * or CPR ID. Results that begin with the search term will be pushed to the top.
   */
  async search(searchTerm: string, countingMethod?: TCountingMethod) {
    if (!searchTerm?.length) {
      return [];
    }

    searchTerm = searchTerm.trim().toLowerCase();

    const part1: ITaxon[] = [];

    const part2 = (await this.getFlat()).filter((taxon) => {
      if (countingMethod && taxon.countingMethod !== countingMethod) {
        return false;
      }
      if (taxon.taxonName.toLowerCase().startsWith(searchTerm)) {
        part1.push(taxon);
        return false;
      }
      if (taxon.taxonName.toLowerCase().includes(searchTerm)) {
        return true;
      }
      if (taxon.cprTaxonId.toString().startsWith(searchTerm)) {
        return true;
      }
      return false;
    });

    return part1.concat(part2);
  }

  async recursiveAcceptedTaxaLookup(acceptedTaxaChain: Array<ITaxon>) {
    if (!acceptedTaxaChain || acceptedTaxaChain.length == 0) {
      return acceptedTaxaChain;
    }

    let latestTaxon = acceptedTaxaChain[acceptedTaxaChain.length - 1];

    if (!latestTaxon.acceptedId || latestTaxon.cprTaxonId == latestTaxon.acceptedId) {
      return acceptedTaxaChain;
    }

    let nextTaxon = await this.getByCprId(latestTaxon.acceptedId);

    if (!nextTaxon) {
      return acceptedTaxaChain;
    }

    acceptedTaxaChain.push(nextTaxon);

    return this.recursiveAcceptedTaxaLookup(acceptedTaxaChain);
  }

  async getAcceptedTaxa(taxa: ITaxon) {
    while (!this.getTaxaInUse(taxa)) {
      taxa = await this.getByCprId(taxa.acceptedId);
    }

    return taxa;
  }

  getTaxaInUse(taxa: ITaxon) {
    return !taxa?.acceptedId || taxa.acceptedId === taxa.cprTaxonId;
  }
}
